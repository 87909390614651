.body {
    margin: 25px;
}

.card-section {
    /* padding-top: 20px; */
    display: flex;
}

.card-info {
    width: 200px;
    text-align: center;
    border: 2px solid #d8dada;
    margin: 10px;
}

.card-data {
    font-weight: bold;
    font-size: 18px;
}

.content-info {
    border: 2px solid #d8dada;
    margin: 10px;
}

.site-attention-msg {
    font-size: 16px;
    font-weight: 400;
}

.show-me {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.padt-15 {
    padding-top: 15px;
}

.button-link {
    display: inline;
    background: none;
    border: none;
    color: #0074d9;
    text-decoration: underline;
    cursor: pointer;
  }
  
.button-link:hover {
    color: #0056b3;
}
  
.button-link:focus {
    outline: none;
}
  
.button-link:active {
    background: none;
}