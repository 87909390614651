.marl-15 {
    margin-left: 15px;
}

.pointer {
    cursor: pointer;
}

.padt-10 {
    padding-top: 10px;
}

.profile-icon {
    width: 40px;
    height: 50px;
    font-size: 28px;
    margin-left: 15px;
    padding-top: 12px;
}

.display-name {
    position: relative;
    top: -5px;
}