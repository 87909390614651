.login-background {
    background-image: url("../images/login-bg.png");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-background-position: center;
    background-position: center;
    overflow: hidden;
    height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.text-label {
    color: #FFFFFF;
}

.mrb-10 {
    margin-bottom: 10px;
}

.mrt-10 {
    margin-top: 10px;
}

.logo {
    background-image: url("../images/max-energy-logo.png");
    background-repeat: no-repeat;
    width: 360px;
    height: 100px;
    border-radius: 10px;
    margin-bottom: 10px;
}