.font-12 {
    font-size: 12px;
}

.font-16 {
    font-size: 16px;
}

.table-header {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.txt-right {
    text-align: right;
}

.padr-20 {
    padding-right: 20px;
}

.padb-10 {
    padding-bottom: 10px;
}

.pad-0 {
    padding: 0px;
}

.spinner-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.spinner-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.app {
    position: relative;
    min-height: 100vh;
}
  
.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.status-header {
    position: relative;
    top: 7px;
}