.body {
    margin: 25px;
}

.user-feild {
    display: flex;
    padding-bottom: 20px;
}

.pad-20 {
    padding: 20px;
}

.padt-30 {
    padding-top: 30px;
}

.padb-30 {
    padding-bottom: 30px;
}

.padl-100 {
    padding-left: 100px;
}

.padr-20 {
    padding-right: 20px;
}

.padr-5 {
    padding-right: 5px;
}

.mar-r-20 {
    margin-right: 20px;
}

.txt-right {
    text-align: right;
}

#password-change .form-control {
    width: 25%;
}