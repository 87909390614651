.p-dialog-content {
    overflow-y: unset;
}

.border-col {
    border: 1.5px solid gray;
}

.worksheet-field {
    color: darkblue;
    font-size: 16px;
}

.worksheet-value {
    color: #000000;
    font-weight: bold;
    font-size: 16px;
}

.signature-dialouge {
    padding: 0px 10px;
}

.worksheet-field .p-inputtext {
    border-radius: 0px !important;
}

.worksheet-field .p-button {
    border-radius: 0px !important;
}

.height-30 {
    height: 30px;
}

.pad-center {
    padding: 0.75rem 0.75rem;
}